;(function() {
"use strict";

angular
    .module('tmr')
    .controller('LoginController', LoginController);

function LoginController($scope, $location, $sce, $stateParams, Animation, ModalFactory, Navigation, Tenant, User, $timeout) {
    $scope.$on('back-to-admin-app', (event) => {
        Navigation.backToAdminApp(User.isLoggedIn());
    });

    // If have are already logged in. Continue.
    if (User.isLoggedIn()) {
        User.logout();
    }

    loginFromSearchParams();
    checkStatus();

    function loginFromSearchParams() {
        let searchParams = $location.search();
        if (searchParams.jwt) {
            $scope.autoLogin = true;
            login(searchParams.jwt);
        }
    }

    function formatToken(token) {
        return token.toUpperCase().replace(/[^A-Z0-9]/g, '');
    }

    function isSurveyPaused() {
        let travelDate = _.getPath(User.getData('const'), 'date');
        if (!travelDate) {
            return false;
        }

        let pausedDates = [
          {
            start: "2021-08-02",
            end:   "2021-08-06",
          },
        ];

        travelDate = new Date(travelDate);

        function inPausedDate(pause) {
            return (travelDate >= new Date(pause.start))
                && (travelDate <= new Date(pause.end));
        }

        return _.some(pausedDates, inPausedDate);
    }

    function login(jwt) {
        $scope.message = "Logging you in...";
        // Strip any non-alphanum chars in the token, and uppercase it.
        let loginPromise = jwt
            ? User.adminLogin(jwt)
            : User.login(formatToken($scope.token), $scope.postcode, $scope.date)

        let defaultMessage = 'Sorry, there was a problem logging you in. Please try again later.';
        loginPromise.then(ok => {
                $scope.message = "Login successful";

                if (isSurveyPaused()) {
                    ModalFactory.open("lockdown.html").result.finally(() => {
                        Navigation.redirectToCompleted();
                    });
                }
                else if (User.isDeleted()) {
                    Navigation.redirectToDeleted();
                }
                else if (!User.canEnterSurvey()) {
                    Navigation.redirectToCompleted();
                }
                else {
                    Navigation.next();
                }
            })
            .catch(failure => {
                switch (failure.status) {
                    case 400:
                    case 403:
                        $scope.message = "Login failed. Please enter in your login details exactly as they appear on the survey kit. Travel date must be entered without slashes, spaces or dashes (e.g. 30062017). If this still does not work please contact the survey office.";
                        break;

                    default:
                        $scope.message = defaultMessage;
                        break;
                }
                $scope.autoLogin = false;
                $timeout(250).then(() => {
                    let firstError = _.first(document.getElementsByClassName('error-message'));
                    Animation.scrollToId(firstError.id).then(() => firstError.focus());
                });
            });
    }

    function toggleTranscript() {
        $scope.showTranscript = !$scope.showTranscript;
    }

    function checkStatus() {
        User.status()
            .then(response => { $scope.status = response.data });
    }

    function getUserAgent() {
        // Our _.getPath fails for this for reasons that aren't quite clearn.
        if (window && window.navigator && window.navigator.userAgent) {
            return window.navigator.userAgent;
        }
        return '';
    }

    // Electron app user agent string Looks like:
    //   Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) SEQTSAdminApp/1.2.1 Chrome/132.0.6834.210 Electron/34.3.0 Safari/537.36
    // Could match on SEQTSAdminApp or Electron here.
    const isElectronApp = getUserAgent().includes('SEQTSAdminApp');

    // Add the api functions into the scope
    _.extend($scope, {
        login:            login,
        scrollToAnchor:   Animation.scrollToAnchor,
        showTranscript:   false,
        toggleTranscript: toggleTranscript,
        status:           { code: 'loading' },
        tenant:           Tenant,
        showYouTube:      !isElectronApp,
        youtubeUrl:       $sce.trustAsResourceUrl('https://www.youtube.com/embed/gF0HorTJY_E'),
    });
}
}());
